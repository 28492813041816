body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MuiSelect-select {
  background-color: white !important;
}

/* media query font resize */
@media screen and (max-width: 600px) {
    .MuiTypography-h1 {
        font-size: 2.5rem !important;
    }
    .MuiTypography-h2 {
        font-size: 2rem !important;
    }
    .MuiTypography-h3 {
        font-size: 1.5rem !important;
    }
    .MuiTypography-h4 {
        font-size: 1.25rem !important;
    }
    .MuiTypography-h5 {
        font-size: 1rem !important;
    }
    .MuiTypography-h6 {
        font-size: 1rem !important;
    }
    .MuiTypography-body1 {
        font-size: 0.9rem !important;
    }
    .MuiTypography-body2 {
        font-size: 0.8rem !important;
    }
    .MuiTypography-caption {
        font-size: 1rem !important;
    }
    .MuiTypography-body {
        font-size: 0.9rem !important;
    }
    .MuiTypography-subtitle1 {
        font-size: 0.9rem !important;
    }
    .MuiDialog-paperWidthMd {
        width: 100% !important;
        max-width: 95% !important;
        margin: 0 !important;
        padding: 0 !important;
    }
}